<template>
	<v-container>
		<div>
            <div class="text-center mt-10">
                <h1>
                    Commissions
                </h1>
                <p class="my-5">
                    Here you can find more information about Commissions Requests from ours talents
                </p>
                <div class="d-inline-flex mt-10 justify-center">
                    <v-img 
                        @click="$refs['ModalNowah'].openModal()" 
                        v-ripple 
                        src="../../assets/img/about/nowah.png" 
                        width="100px" 
                        height="100px" 
                    />
                    <!-- Repetir modal aqui -->                    
                </div>
            </div>
            <v-divider />
			<h1 class="text-center mt-10">Contact Us</h1>
			<v-row class="mt-10">
				<v-col cols="4" class="text-center">
                    <v-icon x-large class="mb-3">mdi-mail</v-icon><br>
                    <a 
                        href="mailto://contato@abismostudios.com.br" 
                        target="_black" 
                    >
                        contato@abismostudios.com.br
                    </a>
				</v-col>
				<v-col cols="4" class="text-center grey darken-3">
                    <v-icon x-large class="mb-3">mdi-map-marker</v-icon><br>
					São Paulo / Rio de Janeiro - BR
				</v-col>
				<v-col cols="4" class="text-center">
                    <v-icon x-large class="mb-3">mdi-phone</v-icon><br>
                    55+ (21) 98288-9189
                </v-col>
			</v-row>
            <v-divider class="mt-10" v-show="false" />
            <div class="form-mail" v-show="false">
                <p>We would love your feedbacks!</p>
                <form target="_black" action="" method="POST">
                    <v-text-field label="Name" type="text" required></v-text-field>
                    <v-text-field label="Email" type="email" required></v-text-field>
                    <v-text-field label="Subject" type="text" required></v-text-field>
                    <v-textarea auto-grow label="Message" required></v-textarea>
                    <div class="text-right mt-2">
                        <v-btn type="submit">
                            <v-icon small class="mr-2">mdi-send</v-icon>
                            Send
                        </v-btn>
                    </div>
                </form>
            </div>
		</div>
        <ModalNowah ref="ModalNowah" />
	</v-container>
</template>

<script>
import ModalNowah from "../../components/ModalNowah.vue";

export default {
	name: "ContactPage",
    components: {
        ModalNowah
    }
};
</script>

<style scoped>
.container > div {
    margin: 4em auto;
    max-width: 1000px;
}

.form-mail {
    max-width: 500px;
    margin: 3em auto 0 auto;
}
</style>