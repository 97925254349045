<template>
    <v-dialog max-width="550px" v-model="modal">
            <v-card class="pb-3">
                <v-btn @click="closeModal" class="btn-close-modal" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-img
                    height="250"
                    src="../assets/img/commission/nowah.jpg"
                ></v-img>
                <v-row class="mx-1 py-5">
                    <v-col cols="12" sm="8">
                        <div>
                            <h2>Nowah-Ru</h2>
                            <h4>Ilustration</h4>
                            <p class="mt-2">
                                Commission do urso<br>
                            </p>
                            <div style="height: 100px"></div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <div>
                            <v-spacer />
                            <v-btn href="https://twitter.com/Nowah_Ru"
                                    target="_blank"
                                    class="my-2"
                                    color="info" block>
                                <v-icon class="mr-2">mdi-twitter</v-icon>
                                Twitter
                            </v-btn>
                            <v-btn href="https://trello.com/b/N4VUKMmm/commissions" 
                                    target="_blank" 
                                    color="info" block>
                                <v-icon class="mr-2">mdi-trello</v-icon>
                                Trello
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
</template>

<script>
export default {
    name:  "ModalNowah",
    data()
    {
        return {
            modal: false
        }
    },
    methods: {
        openModal()
        {
            this.modal = true;
        },
        closeModal()
        {
            this.modal = false;
        }
    }
}
</script>

<style lang="scss" scoped>

.btn-close-modal {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 202;
}

</style>